<template>
  <div class="warehouse-area-container">
    <my-nav-bar
      title="区域排序"
      left-text="返回"
      left-arrow
      :right-text="isSort ? '完成' : '排序'"
      @click-right="isSort = !isSort"
    />
    <div class="app-main-container">
      <div class="warehouse-area-container__choose">
        <van-cell
          title="选择仓库"
          :value="selectedWarehouse ? selectedWarehouse.name : '请选择'"
          is-link
          arrow-direction="down"
          @click="show = true"
        />
      </div>
      <div class="warehouse-area-container__content">
        <van-empty v-if="showEmpty" style="margin-top: 46px" description="暂无数据" />
        <vuedraggable v-model="areas" class="wrapper" :disabled="!isSort">
          <transition-group>
            <div
              v-for="(area, index) in areas"
              :key="index"
              class="warehouse-area-container__cell"
            >
              <van-swipe-cell :disabled="isSort">
                <van-cell :border="false" :title="area.name" :value="index + 1" />
                <template #right>
                  <van-button square type="info" text="编辑" @click="handleEdit(area)" />
                  <van-button square type="danger" text="删除" @click="handleDelete(area)" />
                </template>
              </van-swipe-cell>
            </div>
          </transition-group>
        </vuedraggable>
      </div>
    </div>
    <div class="fixed-bottom-btn warehouse-area-container__footer">
      <!--<van-button type="primary" round :disabled="isSort || areas.length === 0" @click="handleSave">保存修改</van-button>-->
      <van-button square :disabled="isSort" block @click="handleAdd">添加区域</van-button>
    </div>
    <van-action-sheet v-model="show" :actions="warehouses" @select="onSelect" />
    <van-dialog
      v-model="showInputDialog"
      :title="operate === 'edit' ? '编辑区域' : '添加区域'"
      show-cancel-button
      confirm-button-text="确定"
      confirm-button-color="#1989fa"
      @confirm="handleConfirm"
    >
      <div class="input-dialog__content">
        <van-field v-model="operateArea.name" label="区域名称" placeholder="请输入区域名称" />
      </div>
    </van-dialog>
  </div>
</template>

<script>
import warehouseApi from '@/api/warehouse'
import warehouseAreaApi from '@/api/warehouse_area'
import vuedraggable from 'vuedraggable'
import MyNavBar from '../../components/my-nav-bar.vue'
export default {
  name: 'WarehouseAreaIndex',
  components: { vuedraggable, MyNavBar },
  data() {
    return {
      isSort: false,
      selectedWarehouse: null,
      warehouses: null,
      areas: [],
      show: false,
      showEmpty: false,
      showInputDialog: false,
      operateArea: {
        name: ''
      },
      operate: 'add'
    }
  },
  watch: {
    selectedWarehouse() {
      this.getAreas()
    },
    isSort(newVal) {
      if (!newVal) {
        this.handleSave()
      }
    }
  },
  created() {
    this.getWarehouses()
  },
  methods: {
    getWarehouses() {
      warehouseApi.list({ type: 1 }).then(res => {
        this.warehouses = res.data
        this.selectedWarehouse = this.warehouses[0]
      })
    },
    getAreas() {
      warehouseAreaApi.list({ warehouse_id: this.selectedWarehouse.id }).then(res => {
        this.areas = res.data
        this.showEmpty = this.areas.length === 0
      })
    },
    handleSave() {
      const sortData = this.areas.map(area => area.id)
      const data = {
        warehouse_id: this.selectedWarehouse.id,
        area_ids: sortData
      }
      this.beginLoad()
      warehouseAreaApi.sort(data).then(res => {
        this.success(res.msg)
      })
    },
    handleAdd() {
      this.operateArea = {
        warehouse_id: this.selectedWarehouse.id,
        name: ''
      }
      this.operate = 'add'
      this.showInputDialog = true
    },
    onSelect(warehouse) {
      this.selectedWarehouse = warehouse
      this.show = false
    },
    handleEdit(area) {
      this.operate = 'edit'
      this.operateArea = Object.assign({}, area)
      this.showInputDialog = true
    },
    handleDelete(area) {
      this.confirm('确定删除区域吗？').then(() => {
        const data = {
          area_id: area.id
        }
        this.beginLoad()
        warehouseAreaApi.delete(data).then(res => {
          this.success(res.msg)
          this.getAreas()
        })
      })
    },
    handleConfirm() {
      this.beginLoad()
      if (this.operate === 'add') {
        // 添加区域
        const data = {
          warehouse_id: this.operateArea.warehouse_id,
          area_name: this.operateArea.name
        }
        warehouseAreaApi.add(data).then(res => {
          this.success(res.msg)
          this.getAreas()
        })
      } else {
        // 编辑区域
        const data = {
          area_id: this.operateArea.id,
          area_name: this.operateArea.name
        }
        warehouseAreaApi.edit(data).then(res => {
          this.success(res.msg)
          this.getAreas()
        })
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  .warehouse-area-container {
    &__choose {
      position: fixed;
      left: 0;
      right: 0;
      z-index: 10;
    }
    &__content {
      padding: 59px 15px 55px 15px;
    }
    &__cell {
      padding-bottom: 10px;
    }
    &__footer {
      /*background-color: #fff;*/
      /*display: flex;*/
      /*justify-content: space-around;*/
      /*align-items: center;*/
      .van-button {
        /*width: 140px;*/
        height: 50px;
      }
    }
  }
  .input-dialog {
    &__content {
      padding: 10px;
    }
  }
</style>
