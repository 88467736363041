var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "warehouse-area-container"
  }, [_c('my-nav-bar', {
    attrs: {
      "title": "区域排序",
      "left-text": "返回",
      "left-arrow": "",
      "right-text": _vm.isSort ? '完成' : '排序'
    },
    on: {
      "click-right": function clickRight($event) {
        _vm.isSort = !_vm.isSort;
      }
    }
  }), _c('div', {
    staticClass: "app-main-container"
  }, [_c('div', {
    staticClass: "warehouse-area-container__choose"
  }, [_c('van-cell', {
    attrs: {
      "title": "选择仓库",
      "value": _vm.selectedWarehouse ? _vm.selectedWarehouse.name : '请选择',
      "is-link": "",
      "arrow-direction": "down"
    },
    on: {
      "click": function click($event) {
        _vm.show = true;
      }
    }
  })], 1), _c('div', {
    staticClass: "warehouse-area-container__content"
  }, [_vm.showEmpty ? _c('van-empty', {
    staticStyle: {
      "margin-top": "46px"
    },
    attrs: {
      "description": "暂无数据"
    }
  }) : _vm._e(), _c('vuedraggable', {
    staticClass: "wrapper",
    attrs: {
      "disabled": !_vm.isSort
    },
    model: {
      value: _vm.areas,
      callback: function callback($$v) {
        _vm.areas = $$v;
      },
      expression: "areas"
    }
  }, [_c('transition-group', _vm._l(_vm.areas, function (area, index) {
    return _c('div', {
      key: index,
      staticClass: "warehouse-area-container__cell"
    }, [_c('van-swipe-cell', {
      attrs: {
        "disabled": _vm.isSort
      },
      scopedSlots: _vm._u([{
        key: "right",
        fn: function fn() {
          return [_c('van-button', {
            attrs: {
              "square": "",
              "type": "info",
              "text": "编辑"
            },
            on: {
              "click": function click($event) {
                return _vm.handleEdit(area);
              }
            }
          }), _c('van-button', {
            attrs: {
              "square": "",
              "type": "danger",
              "text": "删除"
            },
            on: {
              "click": function click($event) {
                return _vm.handleDelete(area);
              }
            }
          })];
        },
        proxy: true
      }], null, true)
    }, [_c('van-cell', {
      attrs: {
        "border": false,
        "title": area.name,
        "value": index + 1
      }
    })], 1)], 1);
  }), 0)], 1)], 1)]), _c('div', {
    staticClass: "fixed-bottom-btn warehouse-area-container__footer"
  }, [_c('van-button', {
    attrs: {
      "square": "",
      "disabled": _vm.isSort,
      "block": ""
    },
    on: {
      "click": _vm.handleAdd
    }
  }, [_vm._v("添加区域")])], 1), _c('van-action-sheet', {
    attrs: {
      "actions": _vm.warehouses
    },
    on: {
      "select": _vm.onSelect
    },
    model: {
      value: _vm.show,
      callback: function callback($$v) {
        _vm.show = $$v;
      },
      expression: "show"
    }
  }), _c('van-dialog', {
    attrs: {
      "title": _vm.operate === 'edit' ? '编辑区域' : '添加区域',
      "show-cancel-button": "",
      "confirm-button-text": "确定",
      "confirm-button-color": "#1989fa"
    },
    on: {
      "confirm": _vm.handleConfirm
    },
    model: {
      value: _vm.showInputDialog,
      callback: function callback($$v) {
        _vm.showInputDialog = $$v;
      },
      expression: "showInputDialog"
    }
  }, [_c('div', {
    staticClass: "input-dialog__content"
  }, [_c('van-field', {
    attrs: {
      "label": "区域名称",
      "placeholder": "请输入区域名称"
    },
    model: {
      value: _vm.operateArea.name,
      callback: function callback($$v) {
        _vm.$set(_vm.operateArea, "name", $$v);
      },
      expression: "operateArea.name"
    }
  })], 1)])], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }