import request from '@/utils/request'

export default {

  list(params) {
    return request({
      method: 'get',
      url: 'warehouse/area',
      params
    })
  },
  add(data) {
    return request({
      method: 'post',
      url: 'warehouse/area',
      data
    })
  },
  edit(data) {
    return request({
      method: 'put',
      url: 'warehouse/area',
      data
    })
  },
  delete(data) {
    return request({
      method: 'delete',
      url: `warehouse/area/${data.area_id}`
    })
  },
  sort(data) {
    return request({
      method: 'put',
      url: 'warehouse/area/sort',
      data
    })
  }

}
